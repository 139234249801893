document.addEventListener("DOMContentLoaded", () => {
	if (document.querySelector(".divider.first")) {
		gsap.from(".divider.first", {
			left: "-100%",
			duration: 1,
			delay: 0.5,
			scrollTrigger: {
				trigger: ".divider.first",
				start: "10% bottom",
			},
		});

		gsap.from(".divider.first .divider__title", {
			top: "-300px",
			duration: 0.5,
			delay: 1.5,
			scrollTrigger: {
				trigger: ".divider.first",
				start: "10% bottom",
			},
		});

		gsap.from(".divider.first .btn", {
			bottom: "-300px",
			duration: 0.5,
			delay: 2,
			scrollTrigger: {
				trigger: ".divider.first",
				start: "10% bottom",
			},
		});
	}
	if (document.querySelector(".divider.second")) {
		gsap.from(".divider.second", {
			left: "-100%",
			duration: 1,
			delay: 0.5,
			scrollTrigger: {
				trigger: ".divider.second",
			},
		});

		if (!window.matchMedia("(max-width: 576px)").matches) {
			gsap.from(".divider.second .divider__title", {
				top: "-300px",
				duration: 0.5,
				delay: 1.5,
				scrollTrigger: {
					trigger: ".divider.second",
					start: "10% bottom",
				},
			});

			gsap.from(".divider.second .third", {
				bottom: "-300px",
				duration: 0.5,
				delay: 2,
				scrollTrigger: {
					trigger: ".divider.second",
					start: "10% bottom",
				},
			});

			gsap.from(".divider.second .second", {
				left: "-100%",
				duration: 0.5,
				delay: 2,
				scrollTrigger: {
					trigger: ".divider.second",
					start: "10% bottom",
				},
			});

			gsap.from(".divider.second .trigger", {
				right: "-100%",
				duration: 0.5,
				delay: 2,
				scrollTrigger: {
					trigger: ".divider.second",
					start: "10% bottom",
				},
			});
		} else {
			gsap.from(".divider.second .divider__title", {
				top: "-400px",
				duration: 0.5,
				delay: 1.5,
				scrollTrigger: {
					trigger: ".divider.second",
					start: "10% bottom",
				},
			});

			gsap.from(".divider.second .third", {
				bottom: "-300px",
				duration: 0.5,
				delay: 2,
				scrollTrigger: {
					trigger: ".divider.second",
					start: "10% bottom",
				},
			});

			gsap.from(".divider.second .second", {
				left: "-150%",
				duration: 0.5,
				delay: 2,
				scrollTrigger: {
					trigger: ".divider.second",
					start: "10% bottom",
				},
			});

			gsap.from(".divider.second .trigger", {
				right: "-150%",
				duration: 0.5,
				delay: 2,
				scrollTrigger: {
					trigger: ".divider.second",
					start: "10% bottom",
				},
			});
		}
	}
});
