document.addEventListener("DOMContentLoaded", () => {
	if (document.querySelector(".reports__wrapper")) {
		const reports = document.querySelectorAll(".reports__item");
		const newArray = [...reports];
		let cardInline = 0;

		if (window.matchMedia("(min-width: 1470px)").matches) {
			cardInline = 4;
		} else if (window.matchMedia("(min-width: 1200px)").matches) {
			cardInline = 3;
		} else if (window.matchMedia("(min-width: 992px)").matches) {
			cardInline = 2;
		} else if (window.matchMedia("(max-width: 992px)").matches) {
			cardInline = 1;
		}

		let chunk = [];
		while (newArray.length > 0) {
			chunk.push(newArray.splice(0, cardInline));
		}

		if (document.querySelector(".reports .title")) {
			gsap.from(".reports .title", {
				top: "-300px",
				duration: 0.5,
				delay: 0.5,
				scrollTrigger: {
					trigger: ".reports",
					start: "10% bottom",
				},
			});
		}

		for (let i = 0; i < chunk.length; i++) {
			let now = chunk[i];
			let prev =
				i === 0
					? document.querySelector(".reports .title")
					: chunk[i - 1];

			if (cardInline === 4 && now.length % 2 === 0) {
				now.forEach((card, index) => {
					if (index <= 1) {
						gsap.from(card, {
							left: "-100%",
							duration: 0.5,
							delay: index === 1 ? 0.5 : 1,
							scrollTrigger: {
								trigger: ".reports",
								start: "20% bottom",
							},
						});
					} else {
						gsap.from(card, {
							right: "-100%",
							duration: 0.5,
							delay: index === 2 ? 0.5 : 1,
							scrollTrigger: {
								trigger: ".reports",
								start: "20% bottom",
							},
						});
					}
				});
			} else if (cardInline === 4 && now.length % 2 !== 0) {
				gsap.from(now, {
					right: "-100%",
					duration: 0.5,
					delay: 1.5,
					scrollTrigger: {
						trigger: ".reports",
						start: "20% bottom",
					},
				});
			}

			if (cardInline === 3 || cardInline === 1) {
				if (i % 2 === 0) {
					gsap.from(now, {
						left: "-150%",
						duration: 0.3,
						delay: 0.2,
						scrollTrigger: {
							trigger: prev,
							start: "10% bottom",
						},
					});
				} else {
					gsap.from(now, {
						right: "-150%",
						duration: 0.3,
						delay: 0.2,
						scrollTrigger: {
							trigger: prev,
							start: "10% bottom",
						},
					});
				}
			} else if (cardInline === 2) {
				now.forEach((card, index) => {
					if (index == 0) {
						gsap.from(card, {
							left: "-150%",
							duration: 0.3,
							delay: 0.2,
							scrollTrigger: {
								trigger: prev,
								start: "10% top",
							},
						});
					} else {
						gsap.from(card, {
							right: "-150%",
							duration: 0.3,
							delay: 0.2,
							scrollTrigger: {
								trigger: prev,
								start: "0% top",
							},
						});
					}
				});
			}
		}
	}
});
