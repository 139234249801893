document.addEventListener("DOMContentLoaded", () => {
	if (document.querySelector(".amount")) {
		if (window.matchMedia("(min-width: 1470px)").matches) {
			gsap.from(".amount__item-1", {
				left: "-340%",
				duration: 1,
				delay: 1,
				scrollTrigger: {
					trigger: ".amount",
					start: "10% bottom",
				},
			});
			gsap.from(".amount__item-0", {
				left: "-340%",
				duration: 1,
				delay: 1.2,
				scrollTrigger: {
					trigger: ".amount",
					start: "10% bottom",
				},
			});
			gsap.from(".amount__item-5", {
				left: "-340%",
				duration: 1,
				delay: 1,
				scrollTrigger: {
					trigger: ".amount",
					start: "10% bottom",
				},
			});
			gsap.from(".amount__item-4", {
				left: "-340%",
				duration: 1,
				delay: 1.2,
				scrollTrigger: {
					trigger: ".amount",
					start: "10% bottom",
				},
			});

			gsap.from(".amount__item-2", {
				right: "-340%",
				duration: 1,
				delay: 1,
				scrollTrigger: {
					trigger: ".amount",
					start: "10% bottom",
				},
			});
			gsap.from(".amount__item-3", {
				right: "-340%",
				duration: 1,
				delay: 1.2,
				scrollTrigger: {
					trigger: ".amount",
					start: "10% bottom",
				},
			});
			gsap.from(".amount__item-6", {
				right: "-340%",
				duration: 1,
				delay: 1,
				scrollTrigger: {
					trigger: ".amount",
					start: "10% bottom",
				},
			});
			gsap.from(".amount__item-7", {
				right: "-340%",
				duration: 1,
				delay: 1.2,
				scrollTrigger: {
					trigger: ".amount",
					start: "10% bottom",
				},
			});
		} else if (
			window.matchMedia("(min-width: 992px)").matches &&
			window.matchMedia("(max-width: 1465px)").matches
		) {
			gsap.from(".amount__item-0", {
				right: "-500%",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".amount",
					start: "10% bottom",
				},
			});
			gsap.from(".amount__item-1", {
				right: "-500%",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".amount",
					start: "10% bottom",
				},
			});
			gsap.from(".amount__item-2", {
				right: "-500%",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".amount",
					start: "10% bottom",
				},
			});

			gsap.from(".amount__item-3", {
				left: "-500%",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".amount__item-3",
					start: "10% bottom",
				},
			});
			gsap.from(".amount__item-4", {
				left: "-500%",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".amount__item-3",
					start: "10% bottom",
				},
			});
			gsap.from(".amount__item-5", {
				left: "-500%",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".amount__item-3",
					start: "10% bottom",
				},
			});

			gsap.from(".amount__item-6", {
				left: "-500%",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".amount__item-6",
					start: "10% bottom",
				},
			});
			gsap.from(".amount__item-7", {
				left: "-500%",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".amount__item-6",
					start: "10% bottom",
				},
			});
		} else if (
			window.matchMedia("(min-width: 691px)").matches &&
			window.matchMedia("(max-width: 991px)").matches
		) {
			gsap.from(".amount__item-0", {
				right: "-1000%",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".amount",
					start: "10% bottom",
				},
			});
			gsap.from(".amount__item-1", {
				right: "-1000%",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".amount",
					start: "10% bottom",
				},
			});

			gsap.from(".amount__item-2", {
				left: "-1000%",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".amount__item-2",
					start: "10% bottom",
				},
			});
			gsap.from(".amount__item-3", {
				left: "-1000%",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".amount__item-2",
					start: "10% bottom",
				},
			});

			gsap.from(".amount__item-4", {
				right: "-1000%",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".amount__item-4",
					start: "10% bottom",
				},
			});
			gsap.from(".amount__item-5", {
				right: "-1000%",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".amount__item-4",
					start: "10% bottom",
				},
			});

			gsap.from(".amount__item-6", {
				left: "-1000%",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".amount__item-6",
					start: "10% bottom",
				},
			});
			gsap.from(".amount__item-7", {
				left: "-1000%",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".amount__item-6",
					start: "10% bottom",
				},
			});
		} else if (window.matchMedia("(max-width: 691px)").matches) {
			gsap.from(".amount__item-0", {
				right: "-1000%",
				duration: 0.5,
				delay: 0.5,
				scrollTrigger: {
					trigger: ".amount",
					start: "10% bottom",
				},
			});
			gsap.from(".amount__item-1", {
				left: "-1000%",
				duration: 0.5,
				delay: 0.5,
				scrollTrigger: {
					trigger: ".amount__item-0",
					start: "100% bottom",
				},
			});

			gsap.from(".amount__item-2", {
				right: "-1000%",
				duration: 0.5,
				delay: 0.5,
				scrollTrigger: {
					trigger: ".amount__item-1",
					start: "100% bottom",
				},
			});
			gsap.from(".amount__item-3", {
				left: "-1000%",
				duration: 0.5,
				delay: 0.5,
				scrollTrigger: {
					trigger: ".amount__item-2",
					start: "100% bottom",
				},
			});

			gsap.from(".amount__item-4", {
				right: "-1000%",
				duration: 0.5,
				delay: 0.5,
				scrollTrigger: {
					trigger: ".amount__item-3",
					start: "100% bottom",
				},
			});
			gsap.from(".amount__item-5", {
				left: "-1000%",
				duration: 0.5,
				delay: 0.5,
				scrollTrigger: {
					trigger: ".amount__item-4",
					start: "100% bottom",
				},
			});

			gsap.from(".amount__item-6", {
				right: "-1000%",
				duration: 0.5,
				delay: 0.5,
				scrollTrigger: {
					trigger: ".amount__item-5",
					start: "100% bottom",
				},
			});
			gsap.from(".amount__item-7", {
				left: "-1000%",
				duration: 0.5,
				delay: 0.5,
				scrollTrigger: {
					trigger: ".amount__item-6",
					start: "100% bottom",
				},
			});
		}
	}
});
