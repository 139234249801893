document.addEventListener("DOMContentLoaded", () => {
	if (document.querySelector(".openning")) {
		gsap.from(".top", {
			opacity: 0,
			duration: 1,
			delay: 1.5,
		});

		gsap.from(".top__wrapper", {
			opacity: 0,
			duration: 0.5,
			delay: 2,
		});

		gsap.from(".openning-left-3", {
			opacity: 0,
			duration: 1,
			delay: 3,
		});
		gsap.from(".openning-left-5", {
			opacity: 0,
			duration: 1,
			delay: 3,
		});

		gsap.from(".openning-left-2", {
			opacity: 0,
			duration: 1,
			delay: 3.4,
		});
		gsap.from(".openning-left-4", {
			opacity: 0,
			duration: 1,
			delay: 3.4,
		});

		gsap.from(".openning-left-1", {
			opacity: 0,
			duration: 1,
			delay: 3.7,
		});

		gsap.from(".openning-right-3", {
			opacity: 0,
			duration: 1,
			delay: 3,
		});
		gsap.from(".openning-right-5", {
			opacity: 0,
			duration: 1,
			delay: 3,
		});

		gsap.from(".openning-right-2", {
			opacity: 0,
			duration: 1,
			delay: 3.4,
		});
		gsap.from(".openning-right-4", {
			opacity: 0,
			duration: 1,
			delay: 3.4,
		});

		gsap.from(".openning-right-1", {
			opacity: 0,
			duration: 1,
			delay: 3.7,
		});

		gsap.from(".openning-left-mobile", {
			opacity: 0,
			duration: 1,
			delay: 3.7,
		});

		gsap.from(".openning-right-mobile", {
			opacity: 0,
			duration: 1,
			delay: 3.7,
		});

		gsap.from(".openning .top__btn", {
			opacity: 0,
			duration: 0.5,
			delay: 2.2,
		});
		gsap.from(".middle", {
			opacity: 0,
			duration: 0.5,
			delay: 2.4,
		});
		gsap.from(".bottom", {
			opacity: 0,
			duration: 0.5,
			delay: 2.8,
		});

		gsap.from(".bottom__wrapper", {
			opacity: 0,
			duration: 0.5,
			delay: 2.7,
		});
	}
});
