document.addEventListener("DOMContentLoaded", () => {
	if (document.querySelector(".activity")) {
		if (window.matchMedia("(min-width: 992px)").matches) {
			gsap.from(".activity .title", {
				top: "-300px",
				duration: 1,
				delay: 0.5,
				scrollTrigger: {
					trigger: ".activity",
					start: "10% bottom",
				},
			});
			gsap.from(".activity .subtitle", {
				opacity: 0,
				duration: 0.5,
				delay: 1.3,
				scrollTrigger: {
					trigger: ".activity",
					start: "40% bottom",
				},
			});

			gsap.from(".activity__item-2", {
				bottom: "-1000px",
				duration: 1,
				delay: 1.3,
				scrollTrigger: {
					trigger: ".activity",
					start: "20% bottom",
				},
			});
			gsap.from(".activity__item-1", {
				left: "-1000px",
				duration: 1,
				delay: 1.3,
				scrollTrigger: {
					trigger: ".activity",
					start: "20% bottom",
				},
			});
			gsap.from(".activity__item-3", {
				right: "-1000px",
				duration: 1,
				delay: 1.3,
				scrollTrigger: {
					trigger: ".activity",
					start: "20% bottom",
				},
			});
			gsap.from(".activity__item-4", {
				left: "-1000px",
				duration: 1,
				delay: 2,
				scrollTrigger: {
					trigger: ".activity",
					start: "20% bottom",
				},
			});
			gsap.from(".activity__item-5", {
				right: "-1000px",
				duration: 1,
				delay: 2,
				scrollTrigger: {
					trigger: ".activity",
					start: "20% bottom",
				},
			});
		} else {
			gsap.from(".activity .title", {
				top: "-300px",
				duration: 1,
				delay: 0.5,
				scrollTrigger: {
					trigger: ".activity",
					start: "10% bottom",
				},
			});
			gsap.from(".activity .subtitle", {
				opacity: 0,
				duration: 0.5,
				delay: 1.3,
				scrollTrigger: {
					trigger: ".activity",
					start: "10% bottom",
				},
			});

			gsap.from(".activity__item-1", {
				left: "-1000px",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".activity .subtitle",
					start: "100% bottom",
				},
			});
			gsap.from(".activity__item-2", {
				right: "-1000px",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".activity__item-1",
					start: "100% bottom",
				},
			});

			gsap.from(".activity__item-3", {
				left: "-1000px",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".activity__item-2",
					start: "100% bottom",
				},
			});

			gsap.from(".activity__item-4", {
				right: "-1000px",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".activity__item-3",
					start: "100% bottom",
				},
			});

			gsap.from(".activity__item-5", {
				left: "-1000px",
				duration: 0.5,
				delay: 0.2,
				scrollTrigger: {
					trigger: ".activity__item-4",
					start: "100% bottom",
				},
			});
		}
	}
});
