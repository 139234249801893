document.addEventListener("DOMContentLoaded", () => {
	const reports = document.querySelectorAll(".selection__item");

	if (document.querySelector(".selection")) {
		gsap.from(".selection .title", {
			top: "-200px",
			duration: 0.5,
			delay: 0.2,
			scrollTrigger: {
				trigger: ".selection",
				start: "20% bottom",
			},
		});

		for (let i = 0; i < reports.length; i++) {
			let now = reports[i];
			let prev =
				i === 0
					? document.querySelector(".selection .title")
					: reports[i - 1];

			if (window.matchMedia("(min-width: 992px)").matches) {
				gsap.from(now, {
					right: "-200%",
					duration: 1,
					delay: 0.2,
					scrollTrigger: {
						trigger: prev,
						start: "20% bottom",
					},
				});

				gsap.from(now.querySelector(".btn"), {
					right: "-200%",
					duration: 1,
					delay: 0.5,
					scrollTrigger: {
						trigger: prev,
						start: "20% bottom",
					},
				});
			} else {
				gsap.from(now, {
					right: "-200%",
					duration: 1,
					delay: 0.2,
					scrollTrigger: {
						trigger: prev,
						start: "150% bottom",
					},
				});

				gsap.from(now.querySelector(".btn"), {
					right: "-200%",
					duration: 1,
					delay: 0.5,
					scrollTrigger: {
						trigger: prev,
						start: "150% bottom",
					},
				});
			}
		}
	}
});
