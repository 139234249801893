document.addEventListener("DOMContentLoaded", () => {
	if (document.querySelector(".info")) {
		gsap.from(".info", {
			opacity: 0,
			duration: 1,
			delay: 1.7,
		});
		gsap.from(".template", {
			opacity: 0,
			duration: 1,
			delay: 1.7,
		});
		gsap.from(".socials", {
			opacity: 0,
			duration: 1,
			delay: 1.7,
		});
		gsap.from(".form", {
			opacity: 0,
			duration: 1,
			delay: 1.7,
		});
	}
});
