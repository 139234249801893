document.addEventListener("DOMContentLoaded", () => {
	gsap.from(".before", {
		opacity: 0,
		duration: 1,
		delay: 1,
		y: -100,
	});

	gsap.from(".before__wrapper", {
		opacity: 0,
		duration: 1,
		delay: 1,
		y: -200,
	});

	gsap.to(".header__logo-block div", {
		bottom: 60,
		duration: 1,
		delay: 1.5,
	});

	gsap.to(".header__list div", {
		bottom: 200,
		duration: 1,
		delay: 1.7,
	});
	gsap.from(".header__btn", {
		opacity: 0,
		duration: 1,
		delay: 1.7,
	});
});
