gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CSSRulePlugin);

import "./animation/header.js";
import "./animation/banner.js";
import "./animation/amount.js";
import "./animation/divider.js";
import "./animation/activity.js";
import "./animation/selection.js";
import "./animation/reports.js";
import "./animation/themplate.js";
import "./gallery.js";

window.addEventListener("DOMContentLoaded", () => {
	const menu = document.querySelector(".header__list-mobile"),
		menuTrigger = document.querySelector(".header__btn"),
		close = document.querySelector(".close"),
		menuLinks = document.querySelectorAll(".header__list-mobile a");

	menuTrigger.addEventListener("click", () => {
		menu.classList.toggle("active");
	});
	close.addEventListener("click", () => {
		menu.classList.toggle("active");
	});
	menuLinks.forEach((item) => {
		item.addEventListener("click", () => {
			menu.classList.toggle("active");
		});
	});
});
